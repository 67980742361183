import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { Col, Row, Stack } from 'react-bootstrap'
import Layout from '/src/components/layout/checkout'
import { Loading } from '/src/components/loading'
import { getShippingAdjustmentAmount, getTaxAdjustmentAmount } from '/src/components/commerce/checkout/OrderSummary'
import { useOrder, useStores } from '/src/hooks'

import { StoreView } from '/src/components/commerce'
import { formatStores } from '/src/components/commerce/store/util'

import './complete.scss'

const AddressDisplay = ({
  given_name, 
  family_name, 
  address_line1, 
  locality, 
  administrative_area, 
  postal_code 
}) => {
  return (
    <label>
      <span className="name">
        {given_name} {family_name}
      </span>
      <span>{address_line1}</span>
      <span>{locality}, {administrative_area}, {postal_code}</span>
      <span className="estimate">1-5 days estimated dispatch</span>
    </label>
  )
}

const CompleteOrderPickupView = ({ billing_information }) => {
  return (
    <div className="pickup-view-container mb-4">
      <Col xs={6}>
        <p>Please wait until the store has notified you either via phone or email that your order is ready to be collected.</p>
      </Col>
    </div>
  )
}

const CompleteOrderDeliveryView = ({ shipping_information }) => {
  return (
    <div className="delivery-view-container mb-4">
      <Row>
        <Col xs={6}>
          <AddressDisplay {...shipping_information.address} />
        </Col>
      </Row>
    </div>
  )
}

const getCreditCardPaymentType = ({ payment_instrument }) => {
  if (payment_instrument.payment_gateway_id === 'credit') {
    return 'Customer Credit'
  }

  if (payment_instrument.payment_gateway_id === 'cybersource') {
    return 'Credit Card'
  }

  return 'Paypal'
}

const CompleteOrderView = ({ attributes, store }) => {
  const { 
    order_total, 
    order_number, 
    placed, 
    email,
    field_deliver_to,
    field_delivery_notes,
    field_unloading_equipment,
    field_pickup_notes,
    field_pickup_using
  } = attributes
  const shipping = getShippingAdjustmentAmount(order_total)
  const tax = getTaxAdjustmentAmount(order_total)
  const isDelivery = attributes.shipping_method === '4--delivery' || attributes.shipping_method === '--'

  const feature = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: formatStores([{ node: store }])
    }
  }, [store])

  return (
    <div className="complete-pane-container">
      <Row>
        <Col xs={12}>
          <div className='title-container mb-3'>
            <h3 className="title">Order Summary</h3>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <div className="order-complete-container">
            <Row>
              <Col xs={6}>
                <div className="form-item">
                  <label>Order number:</label> {order_number}
                </div>
                <div className="form-item">
                  <label>Order placed:</label> {placed}
                </div>
                <div className="form-item">
                  <label>Order email:</label> {email}
                </div>
                <div className="form-item">
                  <label>Payment:</label> {getCreditCardPaymentType(attributes)}
                </div>
                {isDelivery && field_deliver_to && (
                  <div className="form-item">
                    <label>Deliver to:</label> {field_deliver_to}
                  </div>
                )}
                {isDelivery && field_delivery_notes && (
                  <div className="form-item">
                    <label>Delivery notes:</label> {field_delivery_notes}
                  </div>
                )}
                {isDelivery && field_unloading_equipment && (
                  <div className="form-item">
                    <label>Unloading equipment:</label> {field_unloading_equipment ? 'Yes' : 'No'}
                  </div>
                )}

                {!isDelivery && field_pickup_notes && (
                  <div className="form-item">
                    <label>Pickup notes:</label> {field_pickup_notes}
                  </div>
                )}
                {!isDelivery && field_pickup_using && (
                  <div className="form-item">
                    <label>Pickup using:</label> {field_pickup_using}
                  </div>
                )} 
              </Col>
              <Col xs={6}>
                <Stack className="total-container mb-3" direction='vertical' gap={2}>
                  <label className="sub-total total">
                    Sub total: {order_total.subtotal.formatted}
                  </label>
                  {shipping && (
                    <label className="delivery-total total">
                      Delivery: {shipping.formatted}
                    </label>
                  )}
                  <label className="total-total total">
                    Total: {order_total.total.formatted}
                  </label>
                  {tax && (
                    <label className="gst-total total">
                      Includes {tax.formatted} GST
                    </label>
                  )}
                </Stack>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className='title-container mb-3'>
            <h3 className="title">{isDelivery ? 'Order Delivery To' : 'Order Pickup Information'}</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {isDelivery ? 
            <CompleteOrderDeliveryView {...attributes} /> 
          : 
            <CompleteOrderPickupView {...attributes} />
          }
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className='title-container mb-3'>
            <h3 className="title">{isDelivery ? 'Order Delivery From' : 'Order Pickup From'}</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <StoreView {...store} feature={feature} />
        </Col>
      </Row>
    </div>
  )
}

const CompletePage = ({ uuid, ...props }) => {
  const order = useOrder(uuid)
  const stores = useStores()
  
  const store = useMemo(() => {
    if (!order || !stores) return null
    return stores.find(({ drupal_id }) => drupal_id === order.relationships.preferred_store.data.id)
  }, [stores, order])

  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={12}>
          <div className="title-container">
            {order ? (
              <h1 className="title">Order Complete #{order.attributes.order_number}</h1>
            ): (
              <h1 className="title">Order Complete</h1>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          {order && store ? (
            <CompleteOrderView {...order} store={store} />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export default CompletePage

export const Head = () => <title>Order Complete | Metalcorp</title>
